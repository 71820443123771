<template>
  <li class="dropdown" v-if="user">
    <a id="userSettings" aria-haspopup="true" class="user-settings" data-toggle="dropdown" href="#">
      <span class="user-name">{{user.userName}}</span>
      <span class="avatar">
          <img alt="avatar" src="/img/avatar.png">
        </span>
    </a>
    <div aria-labelledby="userSettings" class="dropdown-menu dropdown-menu-right" v-if="user">
      <div class="header-profile-actions text-center">
        <div class="header-user-profile">
          <div class="header-user">
            <img alt="Admin Template" src="/img/avatar.png">
          </div>
          <h5>{{user.userName}}</h5>
          <p class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 hide-text">{{user.companyName}}</p>
        </div>
<!--        <a data-cy="profile_profile" href="#" id="profileTrigger" data-target="#modalProfile" data-toggle="modal" @click="$emit('update')">-->
<!--          <i class="icon-user1" />{{t('APP.PROFILE')}}-->
<!--        </a>-->
<!--        <a data-cy="profile_change_password" href="#" data-target="#modalPassword" data-toggle="modal" @click="$emit('update')" ><i class="icon-settings1" />{{t('APP.CHANGE_PASSWORD')}}</a>-->
<!--        <a data-cy="profile_singout" href="#" @click.stop.prevent="$emit('logout')"><i class="icon-log-out1" />{{t('APP.SINGOUT')}}</a>-->
      </div>
    </div>
  </li>
</template>

<script>
import {useI18n} from 'vue-i18n';

export default {
  props: ['user'],
  emits:[
    'logout',
    'update'
  ],

  setup() {
    const { t } = useI18n();
    return {t}
  }
}

</script>
<style>
.hide-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
